import React from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import './style.scss'

export default function Header () {
    const {
        site: {
            siteMetadata: { title },
        },
    } = useStaticQuery (
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )
    
    return (
      <header id="luxbar" className="luxbar-default">
        <input type="checkbox" className="luxbar-checkbox" id="luxbar-checkbox"/>
        <div className="luxbar-menu luxbar-menu-right luxbar-menu-light">
            <ul className="luxbar-navigation">
                <li className="luxbar-header">
                    <a href="#" className="luxbar-brand">{title}</a>
                    <label className="luxbar-hamburger luxbar-hamburger-doublespin" 
                    id="luxbar-hamburger" htmlFor="luxbar-checkbox"> <span></span> </label>
                </li>
                <li className="luxbar-item"><a href="/">Blog</a></li>
                <li className="luxbar-item"><a href="/about">About</a></li>
                <li className="luxbar-item"><a href="/contact">Contact</a></li>
            </ul>
        </div>
      </header>
    )
}