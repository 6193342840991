import React from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import './style.scss'

export default function LatestList() {

    const data = useStaticQuery(graphql`
        query latestPosts {
            allMarkdownRemark(
                sort: { fields: [frontmatter___date], order: DESC }
                limit: 2000
            ) {
                edges {
                    node {
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                        }
                    }
                }
            }
        }
   `);

    const posts = data.allMarkdownRemark.edges;
    const allPosts = data.allMarkdownRemark.edges;
    const appPageViews = data.allPageViews;
    const results = [];

    let postsFilterd = []

    posts
    .forEach((post) => {
        if(post.node.fields.slug.includes('/posts')){
            postsFilterd.push(post)
        } else{
        }
    })
    
    //直近の投稿10件に絞ります。
    postsFilterd = postsFilterd.slice(0,10)

    return (
        <div>
        <h1 className="latestlist-header">最近の投稿</h1>
        <table className="latest-list">       
            {postsFilterd.map((result, i) => (
                <tbody key={result.node.frontmatter.title}>
                    <tr key={result.node.frontmatter.title} className="latest-list-item">
                        <td><span><b>{i+1}</b></span></td>  
                        <td className="latest-list-item-box">
                            <Link className="latest-list-item-box-link" to={result.node.fields.slug}>
                                <span style={{display: 'inline'}}>{result.node.frontmatter.title}</span>
                            </Link>
                        </td>
                    </tr>
                </tbody>
            ))}
        </table>
        </div>
    )
};