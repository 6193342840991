import React from "react"
import kebabCase from 'lodash/kebabCase'
import './style.scss'

import { Link, StaticQuery, graphql } from "gatsby"

const CategoryList = () => (
    <StaticQuery
        query={graphql`
            query {
                allMarkdownRemark(limit: 2000) {
                    group(field: frontmatter___category) {
                        fieldValue
                        totalCount
                    }
                }
            }
        `}
        render={data => (
            <nav>
                <h1 className="categorylist-header">カテゴリ一別</h1>
                <ul className="category-list">
                    {data.allMarkdownRemark.group.map(category => (
                        <li className="category-list-item" key={category.fieldValue}>
                            <span className="category-list-icon"></span>
                            <Link className="category-list-item-link" to={`/categories/${kebabCase(category.fieldValue)}/`}>
                                {category.fieldValue} ({category.totalCount})
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        )}
    />
)

export default CategoryList