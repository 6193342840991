import React from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import './style.scss'

export default function Footer () {
    const {
        site: {
            siteMetadata: { title, copyright },
        },
    } = useStaticQuery (
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        copyright
                    }
                }
            }
        `
    )
    
    return (
        <div className="footer">
            <Link className="footer_home" to="/">HOME</Link>
            <p className="footer_copyright">{copyright} {title}</p>
            <div className="footer_pagetop">
                <a href="#"></a>
            </div>
        </div>
    )
}
